<template>
  <div class="headertitle">
    <van-icon v-if="!is_custom_back" @click="goBack"  name="arrow-left" class="goback" />
    <van-icon v-if="is_custom_back" @click="goBackCustom"  name="arrow-left" class="goback" />
    <div class="goback" style="flex: 1;">{{ name | BarName }}</div>
    <!-- <div style="flex: 1;margin-right: 12rem;text-align: right">
        <van-button style="background: #1588F5;color: #fff;font-size: 2.4rem" @touchstart="getTouchStart" @touchend.native.prevent="getTouchEnd">打开钱箱</van-button>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    is_custom_back: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  filters: {
    BarName(val) {
      let obj = {
        'areCancell': "挂单",
        'toTakeOrders': "取单",
        'orderManage': "订单管理",
        'succession': "交接班",
        'addProduct': "新增商品",
        'editorProduct': "编辑商品",
        'afterSaleManagement': "售后管理",
        'featureSet': "系统设置",
        'ShopCategory': '分类管理',
        'settlement': "结算",
        'sellingGoodsList': "销售商品列表",
        'purchase': "退货",
        'returnTheOrder': "退货订单",
        // 'afterSaleManagement': "售后管理",
        'manageProducts': "管理商品",
        'financialControl': "财务管理",
        'inventoryDe': "出库明细",
        'enTrant': "入库明细",
        'employee': "员工管理",
        'progress': "交班记录",
        'addPro': '新增商品',
        'vipManagement': '会员管理',
        'record': '充值订单',
        'writeOff': '核销订单',
        'stockTransfer': '调货管理',
        "addStockTransfer": '新增调货',
        "addMember": "新增会员",
        "editMember": "编辑会员",
        "memberrecord": "消费记录",
        "index-search": "搜索",
        "addQiantaiPrint": "新增前台打印机",
        "editQiantaiPrint": "编辑前台打印机",
        "addWorker": "新增员工",
        "editWorker": "编辑员工",
        "ShopUnit": "单位管理",
        "stockCheck": "库存盘点",
        "stockCheckDetail": "盘点记录",
        "addLabelPrint": "新增标签打印机",
        "editLabelPrint": "编辑标签打印机",
        "labelSetting": "标签设置",
        "toprintForGood": "打印商品标签",
        "coupon-add": "新增优惠券",
        "coupon-edit": "编辑优惠券",
        "member-coupon": "查看优惠券",
        "smsSet": "短信设置",
        "coupon-manage": "优惠券",
        "recharge-scheme": "充值有礼",
        "recharge-scheme-add": "新增方案",
        "recharge-scheme-edit": "编辑方案",
        "exchange-scheme": "积分兑换",
        "exchange-scheme-add": "新增方案",
        "exchange-scheme-edit": "编辑方案",
      };
      return obj[val] || "";
    },
  },
  data() {
    return {
      sufu: 0
    };
  },
  methods: {
    goBackCustom() {
      this.$emit('goBackCustom');
    },
    goBack() {
      this.$router.go(-1);
    },
    getTouchStart() {

      clearTimeout(this.sufu);
      this.sufu = setTimeout(function () {
        //  你要做的功能
        if (typeof KissSunmiPrint !== 'undefined') {
          KissSunmiPrint.myOpenDrawer([])
            .then(function (msg) {
              console.log("============================1" + msg)
            })
            .catch(function (error) {
              console.log("============================2" + error)
            });
        }
      }, 2000);
    },
    getTouchEnd() {
      clearTimeout(this.sufu);
    },
  },
};
</script>
<style lang="less" scoped>
.headertitle {
  width: 100%;
  height: 8.3rem;
  background-color: #fff;
  // padding: 0 3.5rem;
  font-size: 3.2rem;
  font-weight: 400;
  color: #242b37;
  display: flex;
  align-items: center;
  border-bottom: 0.1rem solid #e5e5e5;
  .goback {
    margin-left: 4.1rem;
  }
}
</style>
